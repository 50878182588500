<template>
  <section class="body-sign">
    <transition name="modal" mode="in-out">
      <modal v-if="modal"
             :closemodal="closeModal"
             :errors="[]"
             :title="'Termos e condicoes de uso.'"
             :size="'modal-lg'">
        <template slot="body">
          <div style="color:#333; overflow:auto; max-height: 60vh">
            <p><strong>CONTRATO DE LICENÇA DE USO DE SOFTWARE (TERMO DE USO)</strong></p>
            <p>
              Esta LICENÇA DE USO deve ser lida com atenção antes do SOFTWARE ser utilizado. <br>
              A utilização do SOFTWARE, mesmo que parcial ou a título de teste, indica que a LICENCIADA está ciente dos termos desta LICENÇA DE USO DE SOFTWARE, concordando com os mesmos.<br>
              Em caso de discordância dos termos aqui apresentados, a utilização do SOFTWARE deve ser imediatamente interrompida.<br>
              Esta LICENÇA DE USO DE SOFTWARE se encontra também publicada dentro do próprio SOFTWARE.<br>
              Este CONTRATO DE LICENÇA DE USO DE SOFTWARE representa a prova de LICENÇA DE USO e deve ser tratado como propriedade valiosa.<br>
              O software objeto desta LICENÇA DE USO, nesta LICENÇA DE USO, pode ser tratado também pela denominação de programa de computador, programa, software, sistema ou site.
            </p>
            <p><strong>Período de utilização gratuita</strong></p>
            <p>A LICENCIANTE poderá, oferecer gratuitamente a concessão da LICENÇA DE USO a título de teste, pelo período de 30 (trinta) dias, mediante o cadastramento prévio através do seu site no endereço da web https://app.estoqueauto.com.br/cadastro/dados.</p>
            <p>
              A elegibilidade ao período de utilização gratuita é determinada pela LICENCIANTE a seu exclusivo critério, sendo que poderá limitar tal elegibilidade ou a sua duração para evitar abusos dos períodos de utilização gratuita.<br>
              A LICENCIANTE se reserva o direito de validar o cadastro antes de liberar o acesso para utilização do SOFTWARE, revogar a utilização gratuita e suspender o cadastro se determinar que ele não é elegível.
            </p>
            <p><strong>Não é elegível a período de utilização gratuita</strong></p>
            <ul type="disc">
              <li>Se o CNPJ cadastrado já tiver utilizado o período gratuito anteriormente.</li>
              <li>Se o CNPJ cadastrado não exercer atividades relacionadas a compra e venda de veículos.</li>
              <li>Se o cadastro tiver divergências na validação.</li>
              <li>Se no cadastro for constatado a tentativa de fraude.</li>
            </ul>
            <p><strong>Concessão da LICENÇA DE USO:</strong></p>
            <p>A LICENCIANTE, proprietária dos direitos do software objeto deste contrato, neste ato e pela melhor forma de direito, outorga a LICENCIADA uma LICENÇA DE USO, em caráter não exclusivo e não transferível do programa de computador, objeto desta LICENÇA DE USO, disponibilizado a LICENCIADA para que o utilize via internet, respeitando os termos constantes desta LICENÇA DE USO.</p>
            <p><strong>Adesão ao Contrato:</strong></p>
            <p>A aceitação deste CONTRATO se concretiza na ocasião da utilização, mesmo que parcial ou a título de teste do SOFTWARE, ou na aquisição desta LICENÇA DE USO DE SOFTWARE, feita eletronicamente através da internet ou através de pedido direto à LICENCIANTE ou a uma de suas representantes.</p>
            <p><strong>Normas e condições gerais:</strong></p>
            <p>O SOFTWARE é disponibilizado pela LICENCIANTE para utilização da LICENCIADA via internet. Para que a LICENCIADA possa utilizar o SOFTWARE se faz necessário que o mesmo esteja devidamente instalado e mantido hospedado em um servidor de internet da LICENCIANTE ou outro por ela expressamente homologado.</p>
            <p><strong>Pagamentos, alterações de preços e planos de assinatura:</strong></p>
            <p>
              A LICENCIADA pagará ao LICENCIANTE em modelo pré-pago a mensalidade estipulada de acordo com o plano adquirido. <br>
              Os valores dos serviços e recursos oferecidos pelo LICENCIANTE poderão ser corrigidos anualmente de acordo com o acumulado IPCA do ano anterior todo mês de Janeiro. <br>
              A LICENCIANTE poderá realizar o bloqueio do acesso da LICENCIADA ao SOFTWARE na hipótese do não pagamento pelos serviços prestados após 7 dias do vencimento.<br>
              A LICENCIANTE poderá realizar a suspensão e até o cancelamento dos serviços, com possibilidade de perda total de conteúdo, na hipótese do não pagamento pelos serviços prestados após 30 dias do vencimento.<br>
              Os valores referentes aos pagamentos mensais/trimestrais/anuais dos serviços ora contratados são cobrados antecipadamente.<br>
              O valor a ser cobrado obedece ao plano escolhido pela LICENCIADA.<br>
              Sob nenhuma hipótese o Licenciante estornará de valores pagos à Licenciada.<br>
              Os valores referentes a serviços de homologação, instalações, configurações, manutenções, solicitações de melhorias e outros solicitados futuramente pela LICENCIADA serão previamente orçados e comunicados a LICENCIADA através de nova proposta comercial. <br>
              Novos serviços somente serão executados mediante a aprovação de suas respectivas propostas.<br>
              A LICENCIADA é integralmente responsável pelas informações inseridas no SOFTWARE, pelo cadastramento, permissões, senhas e modo de utilização de seus usuários. <br>
              A LICENCIANTE ou suas REPRESENTANTES, em hipótese alguma serão responsáveis pelo conteúdo (informações, senhas, cópias de informações, etc) de qualquer SOFTWARE, não sendo portanto, estas informações revisadas nem divulgadas em momento algum. <br>
              A responsabilidade pelas informações de cada SOFTWARE é sempre de cada LICENCIADA.<br>
              A LICENCIANTE, pode a qualquer tempo, suspender ou retirar qualquer SOFTWARE que esteja desrespeitando as regras de conteúdo aqui estabelecidas ou as normas legais em vigor, sem qualquer devolução de quantias pagas pela LICENCIADA.<br>
              Sendo desrespeitada qualquer regra aqui estabelecida ou sendo verificada ilegalidade, a LICENCIANTE se reserva do direito de excluir o SOFTWARE além de suspender o direito da mesma LICENCIADA de adquirir novo SOFTWARE da LICENCIANTE.
            </p>
            <p><strong>CONGELAMENTO, BLOQUEIO ou CANCELAMENTO DOS SERVIÇOS</strong></p>
            <p>Em caso de não pagamento dos valores referentes à hospedagem e demais taxas devidas, fica a LICENCIADA sujeita a interrupção temporária ou o cancelamento definitivo dos serviços prestados pela LICENCIANTE. A LICENCIANTE não manterá quaisquer tipos de informações em banco de dados e/ou arquivos, referente a conta da LICENCIADA, após 60 dias da interrupção temporária ou o cancelamento.</p>
            <p>	<strong>Regras de conteúdo</strong></p>
            <p>Regras para publicação de conteúdo: As seguintes regras devem ser obedecidas pela LICENCIADA, visando respeito moral e social, bem como o bom e perfeito funcionamento do(s) PROGRAMA(s) (programa de computador, programa, software, sistema ou site) sendo expressamente proibida a inclusão dos seguintes conteúdos:</p>
            <ul type="disc">
              <li>Publicação ou veiculação de material pornográfico, racista ou ofensivo.</li>
              <li>Incitação ao crime ou informações sobre atividades ilegais.</li>
              <li>Material pornográfico ou atividades ilegais, incluindo menores de 18 (dezoito) anos.</li>
              <li>Páginas criptografadas ou protegidas por senha que contenham informações impróprias definidas nestas regras.</li>
              <li>Material calunioso, que atribua falsamente a alguém fato definido como crime, afirmações injuriosas que ofendam a dignidade ou decoro de alguém bem como afirmações difamatórias, imputando a alguém fato ofensivo à sua reputação.</li>
              <li>Informações relativas à pirataria de software.</li>
              <li>Material protegido por direitos autorais, sendo vetada a publicação de fotos, textos ou arquivos de som sem a autorização do representante da obra ou empresa responsável.</li>
            </ul>
            <p><strong>É expressamente proibido:</strong></p>
            <ul type="disc">
              <li>A utilização do SOFTWARE fora das condições estabelecidas nesta LICENÇA DE USO.</li>
              <li>Traduzir, fazer engenharia reversa, decompilar, copiar imagens, códigos ou quaisquer partes do SOFTWARE para utilização fora dele.</li>
              <li>Alugar, arrendar, atribuir ou transferir o produto licenciado.</li>
              <li>Modificar o produto ou mesclar todas ou qualquer de suas partes com outro programa.</li>
              <li>Remover ou alterar qualquer aviso de copyright, marca registrada, ou outro aviso de direitos de propriedade colocados no SOFTWARE ou em parte do mesmo.</li>
              <li>Em nenhuma hipótese a LICENCIADA terá acesso ao código fonte do SOFTWARE ora licenciado, por este se tratar de propriedade intelectual da LICENCIANTE.</li>
            </ul>
            <p><strong>Suporte Técnico:</strong></p>
            <p> O Suporte Técnico fornecido a LICENCIADA limita-se à esclarecimentos sobre o funcionamento do SOFTWARE e sua operação, assim sendo, pressupõe-se o mínimo de conhecimento do uso do computador por parte do(s) usuário(s), o que inclui o uso do computador e suas funções, o uso do sistema operacional sob o qual o sistema irá trabalhar, e do assunto que o SOFTWARE se propõe a resolver. Pressupõe-se também uma configuração adequada do computador no que se refere a utilização do SOFTWARE adquirido e o bom estado de funcionamento deste computador. O Suporte Técnico limita-se ao atendimento aos clientes, não estando incluídos neste serviço ligações telefônicas, que correm sempre por conta da LICENCIADA. O Suporte Técnico da LICENCIANTE garante atendimento gratuito a LICENCIADA que possui seu SOFTWARE hospedado nos servidores da LICENCIANTE, resumidamente, nos seguintes itens:</p>
            <ul type="disc">
              <li>E-mail em horário comercial para utilização dos recursos constantes no SOFTWARE;</li>
              <li>Manutenção do servidor de internet que hospeda o SOFTWARE;</li>
              <li>Manutenção do link de internet do servidor de hospedagem do SOFTWARE.</li>
            </ul>
            <p>
              O Suporte técnico fornecido pela LICENCIANTE não abrange a conexão com a internet, rede interna e computadores da LICENCIADA.<br>
              Caso a LICENCIADA necessite de modificações no SOFTWARE para que este atenda melhor suas necessidades o procedimento é o seguinte:
            </p>
            <ol start="1" type="1">
              <li>A LICENCIADA contata a LICENCIANTE e informa expressamente o que deseja;</li>
              <li>A LICENCIANTE analisa o pedido da LICENCIADA quanto a sua viabilidade técnica e envia proposta comercial com o valor e o prazo de conclusão dos serviços solicitados;</li>
              <li>Após a aprovação da proposta pela LICENCIADA a LICENCIANTE inicia os serviços e disponibiliza as modificações em outro servidor com uma cópia do SOFTWARE para que a LICENCIADA teste e aprove as novas implementações, para que as mesmas sejam incorporadas ao sistema de produção.</li>
            </ol>
            <p>
              Todas as modificações e correções efetuadas no SOFTWARE, mesmo que informadas, solicitadas, e eventualmente pagas pela LICENCIADA, ficam incorporadas ao SOFTWARE e sujeitas aos termos desta LICENÇA DE USO DE SOFTWARE, podendo inclusive serem disponibilizadas pela LICENCIANTE a outras LICENCIADAS que utilizem o mesmo modelo de SOFTWARE, assim como a LICENCIADA pode eventualmente receber melhorias de funcionamento, correções e novos recursos no SOFTWARE que utiliza com ou sem cobrança adicional, de acordo com o plano escolhido.<br>
              A LICENCIADA desde já concorda com modificações, implementação de novos recursos, ou ferramentas, ou melhorias ou correções no modelo de SOFTWARE que utiliza, ficando a critério da LICENCIANTE o gerenciamento e aprovação destas modificações no SOFTWARE. A instalação das atualizações é feita pela LICENCIANTE de forma automática no SOFTWARE.
            </p>
            <p><strong>SLA (Service Level Agreement):</strong></p>
            <p>
              Denomina-se acordo de nível de serviço ou SLA (Service Level Agreement), para efeito do presente contrato, o nível de desempenho técnico do serviço prestado proposto pela LICENCIANTE, sendo certo que tal acordo não representa diminuição de responsabilidade da LICENCIANTE, mas sim indicador de excelência técnica, uma vez que em informática não existe garantia integral (100%) de nível de serviço. <br>
              A LICENCIANTE, desde que observadas as obrigações a cargo da LICENCIADA previstas no presente contrato, tem condição técnica de oferecer e se propõe a manter, em cada mês civil, um SLA (Service Level Agreement – acordo de nível de serviços ou garantia de desempenho) de 99,8% para cada mês civil.
            </p>
            <p>	<strong>Prazo de Vigência:</strong></p>
            <p> O prazo para utilização do SOFTWARE é definido por tempo indeterminado, ou seja o LICENCIADA pode utilizar o SOFTWARE pelo tempo que desejar, devendo durante o tempo que estiver utilizando o SOFTWARE se manter adimplente com o pagamento mensal de sua hospedagem.</p>
            <p><strong>Condições de Rescisão: </strong></p>
            <p>A LICENCIADA pode rescindir este contrato a qualquer momento, desde que informe expressamente a LICENCIANTE com antecedência de 30 dias. Em nenhuma hipótese serão devolvidos quaisquer valores pagos. A LICENCIANTE pode rescindir este contrato no caso do não cumprimento pela LICENCIADA de suas obrigações contratuais.</p>
            <p><strong>Condições Diversas:</strong></p>
            <p>
              Fica estabelecido também que a LICENCIANTE empregará sempre seus melhores esforços na manutenção e na evolução tecnológica do SOFTWARE e do servidor que o hospeda. <br>
              No caso de interrupção do funcionamento do SOFTWARE, a LICENCIANTE se compromete a repor o igual período da interrupção ao término do prazo estipulado. Em hipótese alguma a LICENCIANTE será responsável por lucros cessantes, indenizações de qualquer espécie, danos comerciais, especiais, acidentais, consequenciais ou outros diretos ou indiretos.<br>
              A utilização do SOFTWARE fica sujeita também as normas estabelecidas no contrato de hospedagem da LICENCIANTE, de acordo com as limitações oferecidas em cada plano de hospedagem de SOFTWARE.<br>
              A LICENCIANTE se reserva do direito de modificar este contrato de acordo com sua necessidade, com intuito de preservar sua propriedade intelectual e o bom funcionamento de todo o sistema.
            </p>
            <p><strong>Foro de eleição:</strong></p>
            <p>Fica eleito, desde já, com exclusão de qualquer outro, por mais privilegiado que seja, o foro da Comarca da cidade de Londrina - PR, para dirimir toda e qualquer dúvida ou pendência oriunda deste contrato.</p>
          </div>
        </template>
      </modal>

    </transition>
      <div class="center-sign">
        <a href="/" class="logo float-left mt-3">
          <img src="/img/logo-short-color.png" height="40" alt="Estoque Auto Logo" />
        </a>
        <div class="panel card-sign">
          <div class="card-title-sign mt-3 text-right">
            <h2 class="title text-uppercase font-weight-bold m-0"><i class="fas fa-user mr-1"></i> Cadastro grátis</h2>
          </div>
          <div class="card-body">
            <h4 class="mt-0 mb-4 font-weight-bold">
              5. Conta<br />
              <small class="text-muted">Informe seus dados de login, em caso de empresa, considere cadastrar no e-mail da mesma.</small>
            </h4>
            <form action="/auth/login" id="form" method="post">
              <fieldset :disabled="loading">
                <div class="form-group mb-3">
                  <label>E-mail</label>
                  <div class="input-group">
                    <input class="form-control form-control-lg" v-model="userData.Email" type="email" @focus="removeError('Email')" />
                    <span class="input-group-append">
                      <span class="input-group-text">
                        <i class="fas fa-at"></i>
                      </span>
                    </span>
                  </div>
                  <span class="text-danger">{{ errors['Email'] }}</span>
                  <span class="text-danger"></span>
                </div>
                <div class="form-group mb-3">
                  <div class="clearfix">
                    <label class="float-left">Senha</label>
                  </div>
                  <div class="input-group">
                    <input class="form-control form-control-lg" v-model="userData.Senha" type="password" @focus="removeError('Senha')" />
                    <span class="input-group-append">
                      <span class="input-group-text">
                        <i class="fas fa-lock"></i>
                      </span>
                    </span>
                  </div>
                  <span class="text-danger">{{ errors['Senha'] }}</span>

                </div>
                <div class="form-group mb-3">
                  <div class="clearfix">
                    <label class="float-left">Confirmação de senha</label>
                  </div>
                  <div class="input-group">
                    <input class="form-control form-control-lg" v-model="userData.ConfirmacaoSenha" type="password" @focus="removeError('ConfirmacaoSenha')" />
                    <span class="input-group-append">
                      <span class="input-group-text">
                        <i class="fas fa-lock"></i>
                      </span>
                    </span>
                  </div>
                  <span class="text-danger">{{ errors['ConfirmacaoSenha'] }}</span>

                </div>
                <div class="form-group mb-3 planos">
                  <div class="clearfix">
                    <label class="float-left">Escolha o plano desejado:</label>
                  </div>
                  <div class="input-group mb-3 ">
                    <div class="radio-custom radio-primary form-control pl-0">
                      <input title="" type="radio" name="plano" id="plano_1" value="1" v-model="userData.Plano" @focus="removeError('Plano')">
                      <label for="plano_1" class="pl-4 pt-2 pr-2 pb-2 d-block">
                        <h4 class="title">Revendedor</h4>
                        <ul class="list-unstyled mb-0 mt-3">
                          <li><i class="fas fa-check "></i> Avaliações ilimitadas</li>
                          <li><i class="fas fa-check"></i> Integração com tabela FIPE</li>

                          <li><i class="fas fa-check"></i> Estoque ilimitado</li>
                          <li><i class="fas fa-check"></i> Despesas ilimitadas</li>

                          <li><i class="fas fa-check"></i> Relatórios</li>
                          <li><i class="fas fa-times pl-1"></i> <del> Integração com outros sistemas</del></li>
                        </ul>
                        <div class="pricing-table-price">
                          <h4>
                            <span class="currency">R$</span>49,99
                            <span class="interval">/mês</span>
                          </h4>
                        </div>
                      </label>
                    </div>
                  </div>
                  <div class="input-group">
                    <div class="radio-custom radio-primary form-control  pl-0">
                      <input title="" type="radio" name="plano" v-model="userData.Plano" value="2" id="plano_2" @focus="removeError('Plano')">
                      <label for="plano_2" class=" pl-4 pl-4 pt-2 pr-2 pb-2 d-block">
                        <h4 class="title">Concessionária</h4>
                        <ul class="list-unstyled mb-0 mt-3">
                          <li><i class="fas fa-check"></i> Avaliações ilimitadas</li>
                          <li><i class="fas fa-check"></i> Integração com tabela FIPE</li>

                          <li><i class="fas fa-check"></i> Estoque ilimitado</li>
                          <li><i class="fas fa-check"></i> Despesas ilimitadas</li>

                          <li><i class="fas fa-check"></i> Relatórios</li>
                          <li><i class="fas fa-check"></i> Integração com outros sistemas *</li>
                        </ul>
                        <div class="pricing-table-price">
                          <h4>
                            <span class="currency">R$</span>115,00
                            <span class="interval">/mês</span>
                          </h4>
                        </div>
                      </label>
                    </div>
                  </div>
                  <span class="text-danger">{{ errors['Plano'] }}</span>

                </div>
                <div class="form-group mb-3">
                  <div class="checkbox-custom checkbox-default">
                    <input type="checkbox" id="term" v-model="userData.Termos" />
                    <label for="term">Eu li e concordo com os <a href="javascript:;" @click="openTermosModal">Termos e condições de uso.</a></label>
                  </div>
                  <span class="text-danger">{{ errors['Termos'] }}</span>

                </div>
                <!--<small><i>* Nosso integrador e</i></small>-->
                <div class="row">
                  <div class="col-sm-12">
                    <button type="button" @click="anterior()" class="btn btn-default mt-2 float-left" id="entrar" :disabled="loading">
                      {{loading ? 'Aguarde' : 'Voltar'}}
                    </button>
                    <button type="button" @click="proximo()" class="btn btn-primary mt-2 float-right" id="entrar" :disabled="loading">
                      {{loading ? 'Aguarde' : 'Concluir'}}
                    </button>
                  </div>
                </div>

              </fieldset>
            </form>
          </div>
        </div>
        <p class="text-center text-muted mt-3 mb-3">&copy; Estoque Auto. Todos os direitos reservados.</p>
      </div>
  </section>
</template>

<script>
import {getCurrentUser,setCurrentUser,userEmpty } from '@/services/external.helpers.js';
import { login,logout } from '@/services/user.service.js';
import api from '@/services/external.api.js';

export default {
    data() {
    return {
          modal: false,
            userData: userEmpty(),
          errors: {},
            loading: false
        };
    },
    created() {
      this.userData = getCurrentUser();

    },
    methods: {
      proximo: function () {
        let that = this;
        if (this.validar()) {
          let form_data = new FormData();
          if (this.userData.Logo) {
            let blob = this.dataURItoBlob(this.userData.Logo);
            form_data.append('LogoFile', blob);
          }
          for (var key in this.userData) {
            if (key !== 'Logo') {
              form_data.append(key, this.userData[key]);
            }
          }
          this.loading = true;
          api
            .post('/external/cadastro', form_data, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }})
            .then(response => {
              if (response.data.success) {
                setCurrentUser(userEmpty());
                login({ Email: that.userData.Email, Password: that.userData.Senha })
                .then(data => {
                    const { obj, msg } = data;
                    if (!obj) {
                        this.errors = msg;
                    }
                    else {
                        this.$root.user = obj;
                        this.$root.authenticated = true;
                        this.$router.push('/');
                    }
                });    
              }
              else {
                displayMessage({ msg: response.data.msg, type: 'error' });
                this.loading = false;
              }
              
            })
            .catch(error => {
              console.log(error);
              this.loading = false;
            });

        }
        else {
          window.scrollTo(0,0);
        }
      },
      anterior: function () {
        setCurrentUser(this.userData);

          this.$router.push('/cadastro/regras');                    
      },
      removeError(prop) {
        this.errors[prop] = '';
        delete this.errors[prop];

        this.$nextTick(() => {
          let obj = new Object();
          for (var i in this.errors) {
            obj[i] = this.errors[i];
          }
          this.errors = obj;
        });
      },
      validar() {
        this.errors = {};
        if (!/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(this.userData.Email)) {
          this.errors['Email'] = 'E-mail inválido.';
        }
        if (this.userData.Senha.length < 6) {
          this.errors['Senha'] = 'A senha deve ter no minimo 6 dígitos.';
        }
        if (this.userData.ConfirmacaoSenha !== this.userData.Senha) {
          this.errors['ConfirmacaoSenha'] = 'A confirmação da senha deve ser idêntica a senha.';
        }

        if (!this.userData.Plano) {
          this.errors['Plano'] = 'Escolha algum plano.';
        }
        if (!this.userData.Termos) {
          this.errors['Termos'] = 'Você deve ler e concordar com os termos e condições de uso.';
        }

        return Object.keys(this.errors).length === 0;
      },
      dataURItoBlob(dataURI) {
          // convert base64/URLEncoded data component to raw binary data held in a string
          var byteString;
          if (dataURI.split(',')[0].indexOf('base64') >= 0)
              byteString = atob(dataURI.split(',')[1]);
          else
              byteString = unescape(dataURI.split(',')[1]);

          // separate out the mime component
          var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

          // write the bytes of the string to a typed array
          var ia = new Uint8Array(byteString.length);
          for (var i = 0; i < byteString.length; i++) {
              ia[i] = byteString.charCodeAt(i);
          }

          return new Blob([ia], {type:mimeString});
      },
      openTermosModal() {
        this.modal = true;
      },
      closeModal() {
        this.modal = false;
      }
    },
}

</script>

<style scoped>
  .radio-custom label:before {
    left:10px
  }
  .radio-custom input[type="radio"]:checked + label:after {
    left: 14px
  }
  .planos li {
    padding: 10px 14px
  }
  .pricing-table-price .currency {
    margin-right: .2rem;
    font-size: 1.3rem;
    position: relative;
    top: -1rem;
  }
  .pricing-table-price h4 {
    text-align: right;
  }
  .pricing-table-price .currency {
    font-size: 0.9rem;
    top: -0.3rem;
  }
  .pricing-table-price .currency {
    margin-top: 0;
    opacity: 0.5;
    font-weight: 400;
  }
  .pricing-table-price .interval {
    font-size: 0.8rem;
}
.pricing-table-price .interval {
    display: inline-block;
}
.pricing-table-price .interval, .pricing-table-price .currency {
    margin-top: 0;
    opacity: 0.5;
    font-weight: 400;
}
.planos .title {
  font-weight:600
}
</style>
